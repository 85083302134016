import Vue from "vue"
import DatePicker from 'vue2-datepicker'
import SortedTablePlugin from "vue-sorted-table"
import Paginate from "@/components/widgets/pagination.vue";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  components: {
    DatePicker,
    Paginate
  },
  data: function() {
    return {
      busy: false,
      date_start: null,
      date_end: null,
      perPage: 6,
      sortBy: 'name',
      currentPage: 1,
      currentData: {},
      sortDesc: false,
      totalItem: 0,
      apiStatus: 'ไม่พบข้อมูล',
      fields: [
        { 
          key: 'doc_no', 
          label: 'เลขที่เอกสาร', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'doc_date', 
          label: 'วันที่เอกสาร', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'ref_no', 
          label: 'เอกสารอ้างอิง', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'type', 
          label: 'ประเภทเอกสาร',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
        },
        { 
          key: 'product_code', 
          label: 'รหัสสินค้า', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        {
          key: 'product_name', 
          label: 'ชื่อสินค้า',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        {
          key: 'product_name', 
          label: 'ชื่อสินค้า',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        {
          key: 'product_name', 
          label: 'ชื่อสินค้า',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        {
          key: 'warehouse', 
          label: 'คลัง',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        {
          key: 'storage', 
          label: 'ที่เก็บ',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        {
          key: 'import', 
          label: 'จำนวนเข้า', 
          sortable: true, 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right' 
        },
        {
          key: 'takeout', 
          label: 'จำนวนออก', 
          sortable: true, 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right' 
        },
        { 
          key: 'balance', 
          label: 'คงเหลือ', 
          sortable: true,
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right' 
        }
      ],
      items: [
        {
          _id: '123456',
          doc_no: 'PO-201665156',
          doc_date: new Date(),
          inv_no: 'IN-202221513',
          type: 'เบิก',
          product_code: 'P-001',
          product_name: 'เหล็ก1',
          warehouse: '001',
          storage: '001',
          import: 37,
          takeout: 2,
          balance: 35
        },
        {
          _id: '123455',
          doc_no: 'PO-201665154',
          doc_date: new Date(),
          inv_no: 'IN-202221514',
          ref_no: 'CN-2022165465',
          type: 'ส่งคืน',
          product_code: 'P-002',
          product_name: 'เหล็ก2',
          warehouse: '001',
          storage: '002',
          import: 27,
          takeout: 2,
          balance: 25
        },
        {
          _id: '123457',
          doc_no: 'PO-201665157',
          doc_date: new Date(),
          inv_no: 'IN-202221515',
          type: 'ขาย',
          product_code: 'P-003',
          product_name: 'เหล็ก3',
          warehouse: '001',
          storage: '003',
          import: 13,
          takeout: 1,
          balance: 12
        },
      ],
      type: null,
      orderTypes: [
        { text: 'สั่งซื้อ', value: 'purchase' },
        { text: 'รับคืน', value: 'take-back' },
        { text: 'นำเข้า', value: 'import' },
        { text: 'ขาย', value: 'sale' },
        { text: 'ส่งคืน', value: 'return' },
        { text: 'เบิก', value: 'withdraw' },
        { text: 'ยกเลิก', value: 'cancel' }
      ]
    }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  methods: {
    onInitData() {
      try {
        const item = this.items
        this.items = []
        this.busy = true
        this.apiStatus = 'กำลังค้นหาข้อมูล..'
        
        setTimeout(() => {
          this.busy = false
          this.apiStatus = 'ไม่พบข้อมูล'
          
          this.items = item
        }, 1000)
      } catch (error) {
        this.busy = false
        this.apiStatus = 'ไม่พบข้อมูล'
        this.onExceptionHandler(error.response.data.message);
      }
    }
  }
}
